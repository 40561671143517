<template>
  <BodyClass class-name="cover home leaf reverse p-0 no-plan">
    <div id="home-vue">
      <div class="fixed-top">
        <div v-if="showTemporaryUse" class="d-flex justify-content-between plan-bar">
          <div class="plan-name">{{ $t('home.serviceProvided') + tenantName }}</div>
          <a class="plan-term" data-bs-toggle="modal" data-bs-target="#confirmTemporaryUseCancel">{{ $t('home.button.planCancel') }}</a>
        </div>

        <div id="notice" class="d-flex align-items-center justify-content-end position-relative notice">
          <div class="g-nav-trigger" :class="{ active: showMenu }" @click.prevent="changeMenu"><span></span><span></span><span></span></div>
          <a v-if="newAnnounce" href="#" class="notice-new view" data-bs-toggle="modal" data-bs-target="#modal-announce"></a>
          <a v-else href="#" class="notice-new view visited" data-bs-toggle="modal" data-bs-target="#modal-announce"></a>
          <!-- ログイン時 -->
          <a v-if="showLogin" href="#" class="notice-user view" data-bs-toggle="modal" data-bs-target="#modal-usermenu"></a>
          <!-- 未ログイン時 -->
          <router-link v-else class="btn login btn-sm ms-2 me-3 rev ico" to="/login">{{ $t('home.header.login') }}</router-link>
        </div>

        <nav id="g-nav" :class="{ panelactive: showMenu }">
          <div class="g-nav-head"></div>
          <div id="g-nav-list">
            <ul>
              <li>
                <router-link class="icon g-nav-icon-usage" to="/howto">{{ $t('home.menu.howto') }}</router-link>
              </li>
              <li>
                <router-link class="icon g-nav-icon-rules" to="/terms">{{ $t('home.menu.terms') }} </router-link>
              </li>
              <li>
                <router-link class="icon g-nav-icon-pinfo" to="/privacyPolicy">{{ $t('home.menu.privacyPolicy') }} </router-link>
              </li>
              <li v-if="showConsultation">
                <router-link class="icon g-nav-icon-cinfo" to="/consultation">{{ $t('home.menu.consultation') }}</router-link>
              </li>
              <li v-if="showLinks">
                <router-link class="icon g-nav-icon-link" to="/links">{{ $t('home.menu.links') }}</router-link>
              </li>
              <li>
                <router-link class="icon g-nav-icon-contact" to="/contact">{{ $t('home.menu.contact') }}</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <main class="container-fluid gx-0">
        <section class="box bg-image">
          <!-- 一時利用またはログイン時はプラスのロゴを表示-->
          <h1 v-if="showTemporaryUse || showLogin" class="logo">{{ $t('title') }}</h1>
          <!-- それ以外は無印のロゴを表示-->
          <h1 v-else class="logo no-login">{{ $t('title') }}</h1>

          <div v-if="showOneMessage" class="kotonoha">
            <h2 class="pt-2 pb-1 text-center position-relative fs-6">
              {{ $t('home.oneMessage.title') }}
            </h2>
            <section v-if="notifications.oneMessage" class="p-0" style="white-space: pre-wrap;">
              {{ notifications.oneMessage.body }}
            </section>
            <section v-else-if="notifications.oneMessage == null" class="p-0" style="white-space: pre-wrap;">
              {{ $t('home.oneMessage.defaultMessage') }}
            </section>
          </div>
          <!-- 一時利用とログインをしていない時に表示-->
          <a v-if="showPromotion" href="/cocoro" class="promotion">{{ $t('home.button.promotion') }}</a>
        </section>

        <section class="box">
          <ul class="menu">
            <li>
              <router-link class="menu-item mn-chat" to="/chat" @click="setTransPage()">{{ $t('home.button.chat') }}</router-link>
            </li>
            <li>
              <router-link class="menu-item mn-posipsy" to="/positiveeq" @click="clickPositiveeqLink()">
                {{ $t('home.button.positiveeq') }}
                <div v-if="!showLogin" class="login-message">{{ $t('home.button.loginMessage') }}</div>
              </router-link>
            </li>
            <li v-if="showChatHistory">
              <router-link class="menu-item mn-history" to="/chatHistoryList">{{ $t('home.button.chatHistory') }}</router-link>
            </li>
            <li>
              <router-link class="menu-item mn-learn" to="/columnList">{{ $t('home.button.column') }}</router-link>
            </li>
          </ul>
          <!-- 一時利用またはログイン時に表示-->
          <div v-if="!showPromotion" class="px-2 text-center">
            <a href="/cocoro" class="col-11 btn btn-outline-primary text-start px-4 py-3 fs-6">{{ $t('home.button.promotion') }}</a>
          </div>
          <div class="links-sns text-center py-2">
            <a class="d-inline-block p-2" :href="$t('link.sns.youtube')" target="_blank"><img src="img/sns/logo_youtube.svg"/></a>
            <a class="d-inline-block p-2" :href="$t('link.sns.facebook')" target="_blank"><img src="img/sns/logo_facebook.svg"/></a>
          </div>
        </section>
        <!-- /section.box -->
      </main>
      <!-- Modals -->
      <!-- ユーザメニュー -->
      <div
        id="modal-usermenu"
        class="modal fade usermenu"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style="z-index: 9998"
      >
        <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header justify-content-center text-center">
              <!-- ログイン時 -->
              <h2 v-if="showLogin" class="modal-title py-2 dialog-info">
                {{ nickname }}<span class="fw-normal">{{ $t('home.userMenu.loginTitle1') }}<br />{{ $t('home.userMenu.loginTitle2') }}</span>
              </h2>
            </div>

            <div class="modal-body p-0 m-0">
              <section class="pane-inner p-0">
                <!-- ログイン時 -->
                <ul v-if="showLogin" class="mb-3">
                  <li class="border-bottom-0">
                    <a class="icon g-nav-icon-profile" href="#" @click.prevent="clickUserReference">{{ $t('home.userMenu.userProfile') }}</a>
                  </li>
                  <li>
                    <a class="icon g-nav-icon-confplan" href="#" @click.prevent="clickServiceStatus">{{ $t('home.userMenu.plan') }}</a>
                  </li>
                  <li>
                    <a class="icon g-nav-icon-logout" href="#" @click.prevent="logout">{{ $t('home.userMenu.logout') }}</a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>

      <!-- お知らせ -->
      <div
        id="modal-announce"
        class="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header justify-content-center rounded-top">
              <h2 class="modal-title pt-3 pb-2 dialog-info">{{ $t('home.modalAnnounce.title') }}</h2>
            </div>

            <div v-if="notifications.announceMessages && notifications.announceMessages.length > 0" class="modal-body p-0">
              <section
                v-for="notification in notifications.announceMessages"
                :key="notification.notificationId"
                class="pane-inner mt-2 p-3"
                style="white-space: pre-wrap;"
              >
                <h3 v-if="notification.newFlag" class="new">{{ notification.notificationName }}</h3>
                <h3 v-else>{{ notification.notificationName }}</h3>
                <p>{{ notification.body }}</p>
              </section>
            </div>
            <div v-else class="modal-body p-0">
              <section class="pane-inner mt-2 p-3" style="white-space: pre-wrap;">
                <p>{{ $t('home.modalAnnounce.defaultMessage') }}</p>
              </section>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="btn btn-outline-primary border-0 px-5 fw-normal" data-bs-dismiss="modal" aria-label="Close">
                {{ $t('home.modalAnnounce.closeButton') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- テナント一時利用解除確認ダイアログ -->
      <DialogConfirm
        name="confirmTemporaryUseCancel"
        :title="$t('home.confirmTemporaryUseCancel.modalTitle')"
        :contents="$t('home.confirmTemporaryUseCancel.modalContents')"
        :yes-button="$t('home.confirmTemporaryUseCancel.modalYesButton')"
        :no-button="$t('home.confirmTemporaryUseCancel.modalNoButton')"
        @yesClick="temporaryUseCancel()"
      />
    </div>
    <Waiting :in-progress="inProgress" />
  </BodyClass>
</template>

<script>
import { Auth } from 'aws-amplify';
import BodyClass from '@/components/form/BodyClass.vue';
import DialogConfirm from '@/components/form/DialogConfirm.vue';
import constants from '@/config/constants';
import http from '@/common/http';
import { isEmpty } from '@/common/validators';
import Waiting from '@/components/form/Waiting.vue';

export default {
  name: 'Home',
  components: {
    BodyClass,
    DialogConfirm,
    Waiting
  },
  data() {
    return {
      showMenu: false,
      notifications: {},
      login: false,
      tenantName: null,
      showPromotion: false,
      inProgress: false
    };
  },
  computed: {
    showChatHistory: function() {
      // ログアウト時にstoreをクリアするストアがクリアされた後またcomputeが走りnullにアクセスしてしまうため、
      // storeでnull判定を行うstore側のgetterを制限する機能の項目名とデフォルト値を引数指定して設定値を取り出す
      // ふりかえる（プランによるふりかえる制御＋ログイン済の場合のみ表示）
      return this.$store.getters['user/permission']('lookBack', false) && this.login;
    },
    showConsultation: function() {
      // 相談窓口情報
      return this.$store.getters['user/permission']('consultationCounter', false);
    },
    showLinks: function() {
      // リンク集
      return this.$store.getters['user/permission']('links', false);
    },
    newAnnounce: function() {
      // 新規フラグが一件でも入っているか
      if (this.notifications.announceMessages) {
        return this.notifications.announceMessages.some(x => x.newFlag);
      }
      return false;
    },
    showOneMessage: function() {
      // ひとことメッセージ
      // 権限がある、かつ、通知取得処理が行われている場合に表示する
      if (this.$store.getters['user/permission']('oneMessage', 10) != 10 && !isEmpty(this.notifications)) {
        return true;
      }
      return false;
    },
    showLogin: function() {
      // ログインチェック
      return this.login;
    },
    showTemporaryUse: function() {
      // テナント一時利用のチェック
      if (this.tenantName != null) {
        return true;
      }
      return false;
    },
    uid: function() {
      return this.$store.getters['user/user_id'];
    },
    nickname: function() {
      return this.$store.getters['user/nickname'];
    },
    tenantId: function() {
      return this.$store.getters['user/tenant_id'];
    }
  },
  mounted: async function() {
    this.inProgress = true;
    // スクロール時、ヘッダーの背景を暗くする処理
    document.addEventListener('scroll', this.scrollEvent);

    // お知らせ、一言メッセージ、テナント名（一時利用の場合）取得
    // データ取得タイミング画面オープン時にすることで、
    // アイコン表示有無、新着を更新する。
    await this.updateHomeInfo();
    await this.loginCheck();

    // テナント一時利用時
    if (this.showTemporaryUse) {
      // ボディのクラスからno-planを削除
      document.body.classList.remove('no-plan');
    }
    // 一時利用をしていない、かつ、ノーログイン時にプロモーションを表示
    if (!this.showTemporaryUse && !this.login) {
      this.showPromotion = true;
    }

    // ポジヘルリンク押下フラグをオフにする
    this.$store.dispatch('positiveeq/disableIsClickPositiveeqLink');

    this.inProgress = false;
  },
  beforeUnmount() {
    // スクロールイベントを解除
    document.removeEventListener('scroll', this.scrollEvent);

    // モーダル背景の削除
    let modalBackDrop = document.querySelector('.modal-backdrop');
    if (modalBackDrop) {
      modalBackDrop.remove();
    }

    // bodyタグに処理上で追加されたスタイルの削除
    document.body.removeAttribute('style');
  },
  methods: {
    changeMenu: function() {
      if (!this.showMenu) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
    logout: async function() {
      this.inProgress = true;
      await Auth.signOut();
      this.$store.dispatch('user/clearUser');
      sessionStorage.clear();
      location.replace('/');
      this.inProgress = false;
    },
    loginCheck: async function() {
      await Auth.currentAuthenticatedUser()
        .then(() => {
          this.login = true;
        })
        .catch(() => {
          this.login = false;
        });
    },
    /**
     * 遷移先設定
     */
    setTransPage: function() {
      this.inProgress = true;

      if (!this.login) {
        // ノーログインの場合
        this.$router.push({ name: 'Info' });
      } else {
        // ログイン済みの場合
        if (this.tenantId.length === 0) {
          // ログインユーザの場合
          this.$store.commit('eqChat/setStartStep', constants.INTRO_STEP_EQ_START);
          this.$store.commit('eqChat/setMode', constants.MODE_INTRO);
          this.$router.push({ name: 'EqChat' });
        } else {
          // LSAユーザの場合
          this.$store.commit('chat/setStartStep', constants.INTRO_STEP_START);
          this.$store.commit('chat/setMode', constants.MODE_INTRO);
          this.$router.push({ name: 'Chat' });
        }
      }

      this.inProgress = false;
    },
    updateHomeInfo: async function() {
      const _this = this;
      const param = {
        NoLoginAccess: true
      };

      // 通知情報を取得
      // お知らせ
      // 今日の一言
      // テナント名（一時利用の場合）
      await http
        .get(constants.API_HOME_URI, param)
        .then(function(response) {
          // 通知情報を設定
          _this.notifications = {
            announceMessages: response.data.announceMessages,
            oneMessage: response.data.oneMessage,
            announceNew: response.data.announceMessages.some(x => x.newFlag),
            oneMessageNew: response.data.oneMessage && response.data.oneMessage.newFlag
          };
          if (!isEmpty(response.data.tenantName)) {
            // テナント名を取得
            _this.tenantName = response.data.tenantName;
          }
        })
        .catch(() => {
          // エラー画面に遷移
          this.$router.push({ name: 'Error' });
        });
    },
    temporaryUseCancel: async function() {
      this.inProgress = true;

      const params = {
        NoLoginAccess: true
      };

      const _this = this;
      await http
        .delete(constants.API_NOLOGINUSER_URI + '/tags/' + this.uid, params)
        .then(response => {
          if (response) {
            // 画面再読み込み
            _this.$store.dispatch('user/clearUser');
            location.replace('/');
          }
        })
        .catch(() => {
          this.$router.push({ name: 'Error' });
        });

      this.inProgress = false;
    },
    scrollEvent: function() {
      if (document.documentElement.scrollTop > 10) {
        document.getElementById('notice').classList.add('view-bg');
      } else {
        document.getElementById('notice').classList.remove('view-bg');
      }
    },
    clickUserReference: async function() {
      this.inProgress = true;

      // プロフィール参照画面に遷移
      this.$router.push({ name: 'UserReference' });

      this.inProgress = false;
    },
    clickServiceStatus: async function() {
      this.inProgress = true;

      // サービス利用状況画面に遷移
      this.$router.push({ name: 'ServiceStatus', state: { fromPage: 'Home' } });

      this.inProgress = false;
    },
    clickPositiveeqLink: function() {
      this.$store.dispatch('positiveeq/enableIsClickPositiveeqLink');
      if (!this.login) {
        // 未ログイン（ゲストユーザ、タグユーザ）の場合、ログイン画面に遷移
        this.$router.push({ name: 'Login' });
      } else {
        // ログイン済（ログインユーザ、LSAユーザの場合、ポジサイホーム画面に遷移
        this.$router.push({ name: 'Positiveeq' });
      }
    }
  }
};
</script>
<style>
.login-message {
  text-align: center;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  line-height: 0.1rem;
}
</style>
